























































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import '../scss/DocumentsActions.scss';
import { namespace } from 'vuex-class';
import { IProject } from '@store/modules/project/Interfaces';
import { IDocument } from '@store/modules/project-documents/Interfaces';
import { ProjectDocumentsActions, ProjectDocumentsMutations } from '@store/modules/project-documents/Types';
import { IUserState } from '@store/modules/user/Interfaces';
import { formatDate } from '@utility/formatDate';
import { IWork, IWorkListState } from '@store/modules/work-list/interfaces/Interfaces';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSWorkList = namespace('storeWorkList');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'DocumentsActions',
    components: {
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        ButtonFavorite: () => import('@components/buttons/ButtonFavorite.vue'),
        BaseActionsGrouped: () => import('@components/BaseActionGrouped/BaseActionsGrouped.vue'),
        PopupReplaceDocuments: () => import('@components/popups/PopupReplaceDocuments.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        PopupFiltersDocuments: () => import('@components/popups/PopupFiltersDocuments.vue'),
        PopupEditDocument: () => import('@components/popups/PopupEditDocument.vue'),
        FiltersPreview: () => import('@components/FiltersPreview/FiltersPreview.vue'),
        BaseViewToggler: () => import('@components/BaseViewToggler'),
    }
})

export default class DocumentsActions extends Vue {

    $refs!: {
        popupReplaceDocuments: HTMLFormElement,
        popupEditDocument: HTMLFormElement,
        filtersPreview: HTMLFormElement,
        filterPopup: HTMLFormElement,
    }

    @Prop({ default: [] }) categoriesForSelect!: string[];
    @Prop({ default: '' }) selectedDocumentationType!: string;
    @Prop({ default: () => ({}) }) textConstants!: { [key: string]: string };
    @Prop({ default: false }) closePopups!: boolean;

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSDocuments.Getter('viewMode') viewMode!: string;
    @NSDocuments.Getter('packPDList') packPDList!: any;
    @NSDocuments.Getter('documents') documents!: IDocument[];
    @NSDocuments.Getter('compositeShow') compositeShow!: boolean;
    @NSDocuments.Getter('selectedDocuments') selectedDocuments!: string[];
    @NSUser.Getter('userData') userData!: IUserState;
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSUser.Getter('isAdminOnCurrentProject') isAdminOnCurrentProject!: boolean;
    @NSDocuments.Getter('agreementProcesses') agreementProcesses!: string[];
    @NSDocuments.Action(ProjectDocumentsActions.A_DOWNLOAD_FILES_ZIP) downloadFilesZip!: (payload: {
        data: {
            appId: string | number;
            ids: string;
        },
        nameArchive: string,
    }) => Promise<void>;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_COMPOSITE_SHOW) setCompositeShow!: (isShow: boolean) => void;
    @NSDocuments.Mutation(ProjectDocumentsMutations.M_SET_SELECTED_DOCUMENTS)
    setSelectedDocuments!: (payload: string[]) => void;
    @NSWorkList.State((state: IWorkListState) => state.viewingWork) viewingWork!: IWork;
    @NSWorkList.State((state: IWorkListState) => state.viewingPackExecutive) viewingPackExecutive!: any;
    @NSDocuments.Action(ProjectDocumentsActions.A_GET_DOC_FILES) getDocFiles!:
        (payload: { appId: number, documentId: string }) => Promise<any>;

    accessDeniedPopupShow: boolean = false;
    accessDeniedText: string = '';
    actionsObjects: any = {
        selectAll: {
            label: 'Выделить всё',
            action: 'selectAll',
        },
        cancelSelected: {
            label: 'Отменить выделение',
            action: 'unselectAll',
        },
        replace: {
            label: 'Переместить',
            action: 'replace',
        },
        download: {
            label: 'Скачать',
            action: 'download',
        },
        edit: {
            label: 'Редактировать',
            action: 'edit',
        },
        delete: {
            label: 'Удалить',
            action: 'delete',
        },
        signature: {
            label: 'Подписать',
            action: 'signature',
        },
        setStatus: {
            label: 'Установить статус',
            action: 'setStatus',
        },
    }

    views: any[] = [
        {
            label: 'Документы по проекту',
            value: 'projectDocs',
        },{
            label: 'Исполнительная документация',
            value: 'executiveDocs',
        },
    ];

    actionOptions: any[] = [];

    @Watch('closePopups')
    watchClosePopups() {
        for (let popup in this.$refs) {
            this.$refs[popup]?.$refs?.basePopup?.closePopup();
        }
        this.$refs?.filterPopup?.$refs?.popupFilter?.$refs?.basePopup.checkClosePopup();
    }

    @Watch('selectedDocuments')
    watchSelectedDocuments() {
        this.onChangeSelectedDocsList();
    }

    mounted() {
        this.resetFilters();
    }

    get filtersBtnDisabled(): boolean {
        if (!this.viewModeExecutive) {
            return false;
        }
        return !((this.compositeShow && this.viewingWork && this.viewingWork.constructs) || (!this.compositeShow && this.viewingPackExecutive.length));
    }

    get viewModeExecutive(): boolean {
        return this.viewMode === 'executiveDocs';
    }

    get titleForDocsTypeFilter() {
        return this.selectedDocumentationType &&
        this.selectedDocumentationType !== this.textConstants.all ? this.selectedDocumentationType : 'Тип документации';
    }

    get nameArchive() {
        let dateNow = formatDate({date: new Date(), format: 'dd_mm_yyyy'});
        return `Documentation_archive_${dateNow}.zip`;
    }

    get currentViewMode() {
        return this.views.filter(view => view.value === this.viewMode)[0] || {};
    }

    onChangeSelectedDocsList() {
        if (this.selectedDocuments.length && this.viewModeExecutive) {
            this.actionOptions = [[
                this.actionsObjects.selectAll,
                this.actionsObjects.cancelSelected,
            ],[
                this.actionsObjects.signature,
                this.actionsObjects.setStatus,
            ]];
        }
        else if (this.selectedDocuments.length && !this.userRightOnlyGSN) {
            this.actionOptions = [
                [
                    this.actionsObjects.selectAll,
                    this.actionsObjects.cancelSelected,
                ],[
                    this.actionsObjects.replace,
                    this.actionsObjects.download,
                    this.actionsObjects.edit,
                ],[
                    this.actionsObjects.delete,
                ],
            ];
        }

        else if (this.selectedDocuments.length && this.userRightOnlyGSN) {
            const selectedDocuments: any[] = [];
            this.documents.forEach(item => {
                if (this.selectedDocuments.includes(item.uuid)) {
                    selectedDocuments.push(item);
                }
            });
            this.$nextTick(() => {
                let dynamicPartActions = [
                    this.actionsObjects.replace,
                    this.actionsObjects.download,
                    this.actionsObjects.edit,
                ];
                let deletePartAction = [
                    this.actionsObjects.delete,
                ];
                selectedDocuments.forEach(item => {
                    if (item.category !== 'Предписание') {
                        dynamicPartActions = [this.actionsObjects.download];
                        deletePartAction = [];
                    }
                });
                const output = [
                    [
                        this.actionsObjects.selectAll,
                        this.actionsObjects.cancelSelected,
                    ],
                    dynamicPartActions,
                ];
                if (deletePartAction.length) {
                    output.push(deletePartAction);
                }
                this.actionOptions = output;
            });
        }
        else {
            this.actionOptions = [[this.actionsObjects.selectAll]];
        }
    }

    onChangeView(e) {
        this.resetFilters();

        if (!this.viewModeExecutive) {
            this.setCompositeShow(false);
        }

        this.$emit('set-view-mode', e);
    }

    resetFilters() {
        if (this.$refs.filterPopup) {
            this.$refs.filterPopup.cancelFilters();
        }
    }

    onSelectAction(e) {
        switch (e.action) {
            case 'selectAll':
                this.selectAll();
                break;
            case 'unselectAll':
                this.setSelectedDocuments([]);
                break;
            case 'download':
                this.downloadDocuments();
                break;
            case 'replace':
                this.openPopupReplaceDocuments();
                break;
            case 'delete':
                this.checkBeforeDelete();
                break;
            case 'edit':
                this.checkBeforeEdit();
                break;
            case 'setStatus':
                this.$emit('open-popup-docs-status');
                break;
            case 'signature':
                this.$emit('open-signature-docs-list-popup');
                break;
            default:
                return false;
        }
    }

    selectAll() {
        const idList: string[] = [];
        this.documents.forEach((item) => {
            idList.push(item.uuid);
        });
        this.setSelectedDocuments(idList);
    }

    downloadDocuments() {
        let countDocuments = 0;
        let nameDocument = '';
        let idsDocument = '';
        let loading = true;
        let errorDownload = '';

        if (this.selectedDocuments.length === 1) {
            idsDocument = this.selectedDocuments[0];
            countDocuments = this.selectedDocuments.length;
            this.documents.forEach((doc) => {
                if (doc.uuid === this.selectedDocuments[0]) {
                    nameDocument = doc.title;
                }
            });
        }

        if (this.selectedDocuments.length > 1) {
            idsDocument = this.selectedDocuments.join(',');
            countDocuments = this.selectedDocuments.length;
        }

        this.$emit('confirm-download-documents', {
            loading,
            nameDocument,
            countDocuments,
        });

        this.downloadFilesZip({
            data: {
                appId: this.projectData.id,
                ids: idsDocument,
            },
            nameArchive: this.nameArchive,
        })
            .catch((error) => {
                errorDownload = error;
            })
            .finally(() => {
                loading = false;
                this.$emit('confirm-download-documents', {
                    loading,
                    nameDocument,
                    countDocuments,
                    errorDownload,
                });
            });
    }

    checkBeforeDelete() {
        const documents = this.documents.filter(doc => this.selectedDocuments.includes(doc.uuid));

        this.$emit('check-before-delete', documents);
    }

    checkBeforeEdit() {
        let canBeEdited:boolean = true;
        const selectedDocId = this.selectedDocuments[0];
        const selectedDoc = this.documents.filter(doc => doc.uuid === selectedDocId)[0];
        if (!this.ifAuthor(selectedDoc) && !this.isAdminOnCurrentProject) {
            canBeEdited = false;
            this.accessDeniedText = 'Вы не можете редактировать документ, т.к. не являетесь его автором или владельцем проекта';
        }
        if (selectedDoc.category === 'Предписание' && !this.userRightOnlyGSN) {
            canBeEdited = false;
            this.accessDeniedText = 'Создавать, редактировать и удалять документы категории "Предписание" может только инспектор ГСН';
        }
        if (canBeEdited) {
            this.openPopupEditDocument(selectedDoc);
        } else {
            this.$emit('set-access-denied-popup-show-edit',
                {  accessDeniedText: this.accessDeniedText || '',
                        accessDeniedPopupShow: true,
                }
            );
        }
    }

    ifAuthor(doc) {
        return doc.author && doc.author.uuid && doc.author.uuid === this.userData.userInfo.id;
    }

    openFilters() {
        this.$refs.filterPopup.openPopup();
    }

    openPopupEditDocument(selectedDoc) {
        this.getDocFiles({
            appId: this.projectData.id,
            documentId: selectedDoc.uuid,
        })
        .then((files) => {
            selectedDoc.files = files;
            this.$refs.popupEditDocument.setSelectedDocument(selectedDoc);
            this.$refs.popupEditDocument.openPopup(this.selectedDocuments);
        })
    }

    setFiltersPreview(filtersData) {
        this.$refs?.filtersPreview?.setFiltersData(filtersData);
    }

    resetFiltersByPreview(data) {
        this.$refs.filterPopup.changeSelected(data, true);
    }

    resetFiltersDateByPreview(data) {
        this.$refs.filterPopup.changeSelected(data, true);
    }

    openPopupReplaceDocuments() {
        this.$refs.popupReplaceDocuments.openPopup(this.selectedDocuments);
    }

    get showNotification() {
        let packEDListHaveTasks = false;
        this.packPDList.forEach((item) => {
            if (!packEDListHaveTasks && item.packED.filter(packEDItem => packEDItem.pddTask).length > 0) {
                packEDListHaveTasks = true;
            }
        })
        return packEDListHaveTasks;
    }
}
